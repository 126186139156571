<template>
  <div>
    <base-table-link
      v-if="!(Object.entries(table.projects).length === 0)"
      :data="table.projects"
      :columns="table.columns"
      thead-classes="text-primary"
    />
    <v-card-text
      v-if="(Object.entries(table.projects).length === 0)"
    >
      <i>There is no project</i>
    </v-card-text>
  </div>
</template>

<script>
  import { BaseTableLink } from '@/components'
  export default {
    components: {
      BaseTableLink,
    },

    data: () => ({}),

    computed: {
      table () {
        return this.$store.state.createdProjects
      },
    },
  }
</script>

<style></style>
