/* eslint-disable vue/script-indent */
<template>
  <v-container
    class="align justify"
    fluid
  >
    <v-toolbar
      color="transparent"
      class="my-4"
      :dark="this.$store.state.darkTheme ? true : false"
    >
      <v-toolbar-title>
        <h1
          v-if="!isMobile()"
          class="font-weight-light"
        >
          Projects on Perseus NG
        </h1>
        <h2
          v-if="isMobile()"
          class="font-weight-light"
        >
          My projects
        </h2>
      </v-toolbar-title>
    </v-toolbar>

    <!-- Boite de dialogue : Stand by -->
    <v-dialog
      v-model="updated"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Bouton action : Join & Create -->
    <v-row class="my-2 text-right">
      <v-col :cols="isMobile()?'':'12'">
        <v-btn
          dark
          color="gred"
          :block="isMobile()"
          large
          to="/my-projects/join-project"
        >
          <v-icon> mdi-account-arrow-right </v-icon>
          <v-icon> mdi-server </v-icon>
          &ensp; join project
        </v-btn>
      </v-col>
      <v-col :cols="isMobile()?'':'12'">
        <v-btn
          v-if="this.$store.state.permanent || this.$store.state.admin"
          dark
          color="gred"
          :block="isMobile()"
          large
          to="/my-projects/create-project"
        >
          <v-icon> mdi-server-plus </v-icon>
          &thinsp; create project
        </v-btn>
      </v-col>
    </v-row>

    <!-- Card : Projects as member -->
    <v-card
      :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
      :dark="this.$store.state.darkTheme ? true:false"
      class="my-4"
      min-width="300px"
      max-width="10000px"
    >
      <v-card-title class="gmiddleblue">
        Projects as member
      </v-card-title>
      <div class="div-table">
        <joined-project-list />
      </div>
    </v-card>

    <!-- Card : Projects as admin -->
    <v-card
      v-if="this.$store.state.permanent || this.$store.state.admin"
      :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
      :dark="this.$store.state.darkTheme ? true:false"
      class="my-4"
      min-width="300px"
      max-width="10000px"
    >
      <v-card-title class="gmiddleblue">
        Projects as admin
      </v-card-title>
      <div class="div-table">
        <created-project-list />
      </div>
    </v-card>

    <!-- Card : Pole project -->
    <v-card
      v-if="this.$store.state.reviewer || this.$store.state.admin"
      :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
      :dark="this.$store.state.darkTheme ? true:false"
      class="my-4"
      min-width="300px"
      max-width="10000px"
    >
      <v-card-title class="gmiddleblue">
        Pole projects &thinsp;
        <v-btn
          :absolute="isMobile()?false:true"
          :right="isMobile()?false:true"
          light
          @click="loadPoleProject"
        >
          <v-icon>mdi-eye</v-icon> &thinsp; Show all projects
        </v-btn>
      </v-card-title>

      <div class="div-table">
        <base-table-link
          v-if="!(Object.entries(polesProjects.projects).length === 0)"
          :data="polesProjects.projects"
          :columns="polesProjects.columns"
          thead-classes="text-primary"
        />
        <v-card-text
          v-if="(Object.entries(polesProjects.projects).length === 0)"
        >
          <i>Please click the "Show all projects" button if you want to get all projects in your pole</i>
        </v-card-text>
      </div>
    </v-card>

    <v-row class="my-12" />
  </v-container>
</template>

<script>
  import config from '@/config'
  import CreatedProjectList from '@/components/table/CreatedProjectList'
  import JoinedProjectList from '@/components/table/JoinedProjectList'
  import { BaseTableLink } from '@/components'
  import axios from 'axios'

  export default {
    components: {
      CreatedProjectList,
      JoinedProjectList,
      BaseTableLink,
    },

    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,
      updated: false,
      polesProjects: {
        title: 'Poles Projects Table',
        columns: ['Name', 'Description', 'Status', 'Laboratory'],
        projects: [],
      },
    }),

    created () {

    },
    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      // Chargement de la liste de projets d'un pole
      loadPoleProject () {
        this.polesProjects = {
          title: 'Poles Projects Table',
          columns: ['Name', 'Description', 'Status', 'Laboratory'],
          projects: [],
        }
        this.updated = true
        axios.get('/getall-projectByPole/' + this.$store.state.listPole[0]).then(response => {
          var List = response.data
          for (let i = 0; i < List.length; i++) {
            axios.get('/get-project/' + List[i])
              .then(response => {
                var project = response.data
                axios.get('/project/' + List[i] + '/get-projectStatus')
                  .then(response => {
                    var tmpStatus = response.data
                    // this.$store.state.polesProjects.projects[String(List[i])].status = response.data
                    axios.get('/project/' + List[i] + '/get-lab')
                      .then(response => {
                        var tmpLaboratory = response.data.slice(2)
                        if (this.polesProjects.projects.length === 0) {
                          this.polesProjects.projects = [{
                            name: project.cn[0],
                            description: project.description[0],
                            status: tmpStatus,
                            laboratory: tmpLaboratory,
                          }]
                        } else {
                          this.polesProjects.projects.push({
                            name: project.cn[0],
                            description: project.description[0],
                            status: tmpStatus,
                            laboratory: tmpLaboratory,
                          })
                        }
                        if (i === List.length - 1) {
                          this.updated = false
                        }
                      })
                  })
              })
          }
        })
      },
      hasValue (item, column) {
        return item[column.toLowerCase()] !== 'undefined'
      },
      itemValue (item, column) {
        return item[column.toLowerCase()]
      },
      colorON () {
        if (this.$store.state.darkTheme) {
          return 'white'
        } else {
          return 'black'
        }
      },
    },

  }
</script>

<style lang="scss">
  .div-table {
    overflow-x: auto;
    margin-right: 2pc;
  }
</style>
